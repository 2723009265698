/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

import React from 'react'
import {connect} from 'react-redux';
import {Switch, Route, Redirect} from 'react-router-dom';
import PresentationLayout from './presentation-layout'

const PrimaryLayout = ({summit, speaker, member, selectionPlanId, match}) => {
  const loggedUser = (speaker && speaker.id) ? speaker : member;
  const selectionPlan = summit.selection_plans.find(sp => sp.id === selectionPlanId);

  if (!loggedUser || !selectionPlanId || !selectionPlan) return null;

  return (
    <>
      <Switch>
        <Route
          path={`${match.url}/new`}
          render={(props) => <PresentationLayout selectionPlan={selectionPlan} {...props}/>}
        />
        <Route
          path={`${match.url}/:presentation_id(\\d+)`}
          render={(props) => <PresentationLayout selectionPlan={selectionPlan} {...props}/>}
        />
        <Route render={props => (<Redirect to={`/app/${summit.slug}/all-plans/${selectionPlanId}`}/>)}/>
      </Switch>
    </>
  );

}

const mapStateToProps = ({loggedUserState, baseState}) => ({
  member: loggedUserState.member,
  speaker: baseState.speaker,
  summit: baseState.summit,
  loading: baseState.loading,
});

export default connect(mapStateToProps, {})(PrimaryLayout)


